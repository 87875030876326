@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html,
  body {
    @apply font-inter;
  }

  img {
    @apply max-w-full max-h-full object-cover;
  }

  p {
    @apply text-body !text-sm;
  }
}

@layer components {
  .status {
    @apply px-2.5 py-1 select-none text-xs font-medium leading-tight rounded-sm;
  }
  .status-done {
    @apply status text-status-complete-text bg-status-complete-bg;
  }
  /* .status-danger {
    @apply status text-status-danger-text bg-status-danger-bg;
  } */
  .status-pending {
    @apply status text-status-pending-text bg-status-pending-bg;
  }
  .status-waiting {
    @apply status text-status-waiting-text bg-status-waiting-bg;
  }
  .status-info {
    @apply status text-status-info-text bg-status-info-bg;
  }
  .status-draft {
    @apply status text-status-draft-text bg-status-draft-bg;
  }

  .status-success {
    @apply status text-status-success-text bg-status-success-bg;
  }
  .status-danger {
    @apply status text-status-danger-text bg-status-danger-bg;
  }

  .cancel-btn {
    @apply !border !border-solid  !border-others-cancel !min-w-[5rem] !rounded-md !text-sm !font-medium !leading-normal !text-others-header;
  }
  .draft_btn {
    @apply bg-status-danger-bg !border-status-danger-bg !min-w-[5rem] !rounded !text-sm !font-medium !leading-normal !text-primary;
  }
}

/* ===== START: change scrollbar color and width ===== */
::-webkit-scrollbar {
  @apply w-1 h-3 rounded;
}
::-webkit-scrollbar-thumb {
  @apply bg-accent/50 rounded;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-accent;
}

.custom-scrollbar,
.custom-scrollbar .ant-table-content {
  scrollbar-color: #fff #fff !important;
  scrollbar-width: none !important;
}

.custom-scrollbar:hover,
.custom-scrollbar .ant-table-content:hover {
  scrollbar-width: thin !important;
}

.custom-scrollbar:hover,
.custom-scrollbar .ant-table-content:hover {
  scrollbar-color: #ddd #fff !important;
}
/* ===== END: change scrollbar color and width ===== */

/* ===== START: print design ===== */
@media print {
  .common_print > h4 {
    @apply block;
  }
}
/* ===== END: print design ===== */

.profilePicture {
  @apply w-10 h-10 rounded-full;
}
