/* START: Others */
.ant-layout {
  @apply !bg-[#fff];
}
.ant-comment-inner {
  @apply !py-2;
}
.toast-message .ant-notification-notice-message {
  @apply !mb-0;
}
.profile-menu .ant-dropdown-menu .ant-dropdown-menu-item {
  @apply px-0;
}
.ant-breadcrumb-separator {
  @apply !mx-1;
}
.multiple_select .ant-select-selection-item-content {
  @apply !flex items-center;
}
.slide_filter_section
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply !h-[36px];
}
.slide_filter_section .ant-select-selector .ant-select-selection-placeholder,
.ant-select-selection-item {
  @apply !pt-[2px];
}
.custom-message .ant-message-notice {
  @apply !flex !justify-end !pr-4;
}
/* timeline */
.timeline_inactive .ant-timeline-item-tail {
  @apply !border-[#AFBACA];
}
.timeline_active.ant-timeline-item-last,
.timeline_inactive.ant-timeline-item-last {
  @apply !pb-0;
}
/* comment area */
.application-comment .ant-comment-content-author {
  @apply items-center;
}
/* phone number with country input */
.phone_input .PhoneInputInput {
  height: auto !important;
  touch-action: manipulation;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
  padding: 7px;
  color: #262626;
  @apply focus-visible:outline-none border border-solid border-gray-300 hover:border-primary;
}
/* END: Others */

/* ====== START: sidebar menu ======> */
.custom_sidebar,
.custom_sidebar .sidebar-menu-item {
  @apply !bg-white;
  /* border-r border-y-0 border-l-0 border-solid border-r-[#E9EFF6] */
}
.sidebar-menu-item {
  @apply !px-4 !select-none;
}
.sidebar_collapsed {
  @apply !px-3;
}
.ant-menu-sub {
  @apply !bg-white !pl-6 !-mt-1.5;
}
.ant-menu-inline .ant-menu-item {
  @apply !h-10;
}
.ant-menu-item,
.ant-menu-submenu {
  @apply !text-body hover:!text-primary transition-colors;
}
.ant-menu-item,
.ant-menu-submenu-title {
  @apply !pl-3;
}
.ant-menu-submenu-selected .ant-menu-submenu-title svg,
.ant-menu-item.ant-menu-item-selected svg {
  @apply !text-primary;
}
.ant-menu-item svg,
.ant-menu-submenu svg,
.ant-menu-submenu .ant-menu-submenu-arrow {
  @apply !text-others-gray;
}
.ant-menu-item.ant-menu-item-active:hover svg,
.ant-menu-submenu-active:hover .ant-menu-submenu-title svg,
.ant-menu-submenu-open .ant-menu-submenu-title svg,
.ant-menu-item.ant-menu-item-active:hover .ant-menu-submenu-arrow,
.ant-menu-submenu-active:hover .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow {
  @apply transition-colors !text-primary;
}
.ant-menu-submenu.ant-menu-submenu-vertical:hover .ant-menu-title-content {
  @apply !hidden;
}
.ant-menu-title-content {
  @apply !transition-none !pl-2 !text-base !font-normal !text-body hover:!text-primary;
}
.ant-menu-submenu:hover .ant-menu-submenu-title .ant-menu-title-content {
  @apply !text-primary;
}
/* .ant-layout-sider-collapsed .sidebar_collapsed {
  @apply flex items-center flex-col;
} */
.ant-layout-sider-collapsed .sidebar_collapsed {
  @apply !ml-1;
}
.ant-layout-sider-collapsed .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-submenu-title {
  @apply !p-0 !m-0 !relative;
}
.ant-layout-sider-collapsed .ant-menu-item svg,
.ant-layout-sider-collapsed .ant-menu-submenu-title svg {
  @apply absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4;
}
/*
.ant-layout-sider-collapsed .ant-menu-title-content {
  @apply hidden;
}
*/
.ant-layout-sider-collapsed .ant-menu-submenu-title {
  @apply !mb-2;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu .ant-menu-submenu-title {
  @apply !text-body hover:!text-primary;
}
.ant-menu-item-selected,
.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected,
.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content {
  @apply !bg-[#FBE9EB] !text-primary hover:!text-primary !transition-colors !text-base
  !font-normal !rounded;
}
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item {
  @apply !h-8 !mb-0 last:!mb-1 flex items-center;
}
.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop {
  @apply !left-[72px];
}
.ant-menu-vertical .ant-menu-title-content {
  @apply !text-transparent hover:!text-transparent;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  @apply !pl-3 !pt-2;
}
.ant-menu-vertical .ant-menu-submenu-title {
  @apply !pt-1.5;
}
.ant-menu-vertical .ant-menu-item {
  @apply !h-[42px] !mb-2;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-sub .ant-menu-item {
  @apply !mb-0 !h-8;
}

/* dropdown popup */
.ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
  @apply !text-primary;
}
.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title {
  @apply !text-primary;
}
.ant-menu-submenu-vertical .ant-menu-submenu-title .ant-menu-title-content {
  @apply !text-transparent;
}
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item-active {
  @apply !transition-none;
}
.ant-menu-submenu-popup .ant-menu-vertical {
  @apply !bg-white !shadow;
}
.ant-menu-submenu-popup .ant-menu-vertical {
  @apply !pl-0;
}
.ant-menu-submenu-popup .ant-menu-title-content {
  @apply !text-body hover:!text-primary;
}
.ant-menu-submenu-popup .ant-menu-item {
  @apply !pt-[3px];
}
/* <====== END: sidebar menu ====== */

/* ===== START: custom form start ===== */

.password_input .ant-input-suffix {
  @apply after:hidden;
}
.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  @apply !text-body;
}
.filter_area .ant-select-selector {
  @apply !border-[#afbaca] !border !border-solid;
}
.ant-form-item-label {
  @apply !pb-1;
}
.ant-form-item-explain-error {
  @apply text-[12px];
}
.custom-search-input .ant-input {
  @apply pb-[7.5px];
}
.custom-form .hide_star_mark .ant-form-item-label label {
  @apply before:!content-none;
}

.custom-form
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: none !important;
}

.custom-form .ant-select-lg {
  @apply !h-[45px];
}
.custom-form .ant-form label {
  @apply !text-others-header;
}
.custom-form .login-form .ant-form-item-label label {
  @apply !h-auto;
}
.custom-form .login-form .ant-form-item {
  @apply !mt-4;
}
.custom-form .ant-form-item-label {
  @apply !pb-0.5;
}
.custom-form .ant-form-item-label label {
  @apply !text-sm !font-medium !text-header !h-auto !pb-1 !leading-normal;
}
.custom-form .ant-form-item-required {
  @apply before:absolute before:right-0 before:top-0;
}
.custom-form .ant-input-lg,
.custom-form .ant-picker-large {
  @apply !py-[12px] !px-3 !text-sm;
}
.custom-form .ant-input-affix-wrapper-lg {
  @apply !py-0;
}
.custom-form .ant-input-affix-wrapper-lg .ant-input-lg {
  @apply !px-0;
}
/* .custom-form .ant-input-affix-wrapper-lg .ant-input-lg,
.custom-form .ant-input-number-lg {
  @apply !py-0.5 !px-0;
} */
.custom-form .ant-select-lg .ant-select-selector {
  @apply !h-[46px];
}
.custom-form .ant-input-number-lg input.ant-input-number-input {
  @apply !h-[44px];
}
.custom-form .ant-checkbox-group .ant-checkbox-wrapper span {
  @apply text-body select-none text-base font-normal;
}
.custom-form
  .ant-checkbox-group
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  @apply !bg-[#455468]  !border-[#455468];
}
.custom-form .form_dark_label .ant-form-item-label label {
  @apply !text-header;
}
.custom-form .ant-input-affix-wrapper .ant-input-prefix,
.custom-form .ant-input-affix-wrapper .ant-input-suffix,
.custom-form .ant-select-suffix {
  @apply relative after:content-[''] after:absolute  after:top-2/4 after:-translate-y-2/4 after:w-[1px] after:bg-[#D7DFE9];
}
.custom-form .ant-input-affix-wrapper .ant-input-prefix {
  @apply !mr-4  after:-right-2.5 after:h-4;
}
#form_item_path.custom-form .ant-checkbox-inner {
  @apply !rounded-sm;
}
#form_item_path.custom-form .ant-checkbox-wrapper:hover .ant-checkbox:after {
  @apply !invisible;
}
/* .custom-form .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-form .ant-checkbox-wrapper .ant-checkbox-checked:after {
  @apply !border-[#455468] !shadow-none;
} */
.custom-form .ant-select-lg .ant-select-selection-placeholder,
.custom-form .ant-select-lg .ant-select-selector .ant-select-selection-item {
  @apply !leading-[2.7rem] !text-sm !font-medium;
}
.custom-form .ant-input-affix-wrapper .ant-input-prefix,
.custom-form .ant-input-number-affix-wrapper .ant-input-number-prefix,
.custom-form .ant-input-affix-wrapper .ant-input-suffix,
.custom-form .ant-select-suffix,
.custom-form .ant-picker-suffix,
.top_bar_input .ant-input-suffix,
.custom-form
  .ant-input-number-group-wrapper
  .ant-input-number-group-addon
  span {
  @apply relative after:content-[''] after:absolute  after:top-2/4 after:-translate-y-2/4 after:w-[0.5px] after:bg-[#D7DFE9];
}
.custom-form .ant-input-affix-wrapper .ant-input-prefix,
.custom-form .ant-input-number-affix-wrapper .ant-input-number-prefix {
  @apply !mr-4  after:-right-2.5 after:h-4;
}
.custom-form .ant-select-arrow .ant-select-suffix,
.custom-form .ant-input-affix-wrapper .ant-input-suffix,
.top_bar_input .ant-input-suffix,
.custom-form .ant-picker-suffix,
.custom-form
  .ant-input-number-group-wrapper
  .ant-input-number-group-addon
  span {
  @apply after:-left-2.5 after:h-3.5;
}
.custom-form .ant-select .ant-select-selector input {
  @apply !pr-7;
}
.custom-form .ant-select-selection-placeholder {
  @apply !text-sm !font-medium !text-[#8897AE] relative -top-[1.2px] !mt-0.5;
}
.delete_btn .ant-btn-default {
  @apply !bg-status-danger-bg disabled:!bg-status-danger-bg/50 disabled:!border-transparent hover:!bg-status-danger-bg/90 !rounded-lg !text-base !border-status-danger-bg !font-medium leading-7 !text-status-danger-text disabled:shadow-none hover:shadow-sm;
}
/* change input focuse color */
.custom-form .ant-input,
.custom-form .ant-input-affix-wrapper,
/* .custom-form .ant-input-number, */
.custom-form .ant-select-selector,
.custom-form .ant-upload .ant-btn,
.custom-form .ant-picker {
  @apply border !border-solid !border-others-border relative !rounded bg-white placeholder:!text-others-gray placeholder:!text-sm placeholder:!font-medium  placeholder:!leading-normal !text-body;
}
.custom-form .ant-input-number-affix-wrapper,
.custom-form .ant-input-number {
  @apply !rounded !border-others-border hover:!border-others-border;
}
.custom-form .ant-input-number-affix-wrapper .ant-input-number-input-wrap {
  @apply !pl-1;
}
.custom-form .ant-upload .ant-btn {
  @apply !h-[46px];
}
.custom-form .ant-picker input,
.custom-form .ant-input-number input {
  @apply placeholder:!text-others-gray placeholder:text-sm placeholder:font-normal placeholder:leading-normal !text-body placeholder:!relative placeholder:!top-[0.2px] !text-sm;
}
.custom-form .ant-picker-suffix,
.custom-form .ant-select-arrow .ant-select-suffix {
  @apply text-body;
}
.custom-form .ant-input-number input {
  @apply placeholder:relative placeholder:-top-[1px];
}
.custom-form .ant-input-prefix,
.custom-form .ant-input-number-prefix {
  @apply text-body text-base font-medium leading-7;
}
.custom-form .ant-input-status-error,
.custom-form .ant-input-affix-wrapper-status-error,
.custom-form .ant-input-number-status-error.ant-input-number,
.custom-form .ant-input-number-affix-wrapper-status-error,
.custom-form .ant-select-status-error .ant-select-selector,
.custom-form .ant-checkbox-inner-status-error,
.custom-form .ant-form-item-has-error .custom-upload .ant-btn,
.custom-form .ant-picker-status-error {
  @apply border !border-solid !border-[#ff3838] hover:!border-[#ff3838];
}
.custom-form
  .ant-input-number-group-wrapper-status-error
  .ant-input-number-group-addon {
  @apply !border-r-[#ff3838] !border-y-[#ff3838];
}
.custom-form
  .ant-input-number-affix-wrapper-status-error
  .ant-input-number-status-error.ant-input-number {
  @apply !border-0;
}
.custom-form .ant-form-item-has-error .ant-form-item-label label,
.custom-form .ant-form-item-has-error .ant-form-item-explain-error,
.custom-form .ant-form-item-has-error .ant-upload > span,
.custom-form .ant-form-item-has-error .ant-input-prefix,
.custom-form .ant-form-item-has-error .ant-input-number-prefix,
.custom-form .ant-form-item-has-error .ant-input,
.custom-form .ant-form-item-has-error .ant-input-number-input,
.custom-form .ant-form-item-has-error .ant-picker-input input,
.custom-form .ant-form-item-has-error .ant-upload p,
.custom-form
  .ant-form-item-has-error
  .ant-input-suffix
  .ant-input-password-icon {
  @apply !text-[#e82215] placeholder:!text-[#e82215];
}
.custom-form .ant-form-item-has-error .ant-input-prefix,
.custom-form .ant-form-item-has-error .ant-input-number-prefix,
.custom-form .ant-form-item-has-error .ant-input-suffix,
.custom-form .ant-form-item-has-error .ant-input-number-su.ant-input-suffix,
.custom-form .ant-form-item-has-error .ant-picker-suffix,
.custom-form .ant-form-item-has-error .ant-select-suffix {
  @apply after:!bg-[#ff574c];
}
.custom-form .ant-form-item-has-error button,
.custom-form .ant-form-item-has-error .ant-input-affix-wrapper-status-error,
.custom-form .ant-form-item-has-error .ant-input,
.custom-form
  .ant-form-item-has-error
  .ant-input-number-affix-wrapper-status-error,
.custom-form .ant-form-item-has-error .ant-input-number,
.custom-form .ant-form-item-has-error .ant-picker-status-error,
.custom-form .ant-form-item-has-error .ant-select-selector {
  @apply !bg-[#fff5f4];
}
.custom-form .ant-form-item-has-error button > span {
  @apply !bg-[#ff574c];
}
.custom-form .ant-form-item-has-error button > div,
.custom-form .ant-form-item-has-error .ant-picker-suffix,
.custom-form .ant-form-item-has-error .ant-select-suffix,
.custom-form .ant-form-item-has-error .ant-select-selection-placeholder {
  @apply !text-[#ff3838];
}
.custom-form .ant-input-affix-wrapper .ant-input {
  @apply !border-none;
}
.custom-form .ant-form-item {
  /* @apply !mb-4;  omar */
  @apply !mb-2.5;
}
.custom-form .form_item_row .ant-form-item-row {
  @apply !flex-row !items-center justify-between;
}
.custom-form .form_item_row .ant-form-item-label,
.custom-form .form_item_row .ant-form-item-label label {
  @apply !pb-0 !inline-block;
}
.custom-form .form_item_row .ant-form-item-control {
  @apply !w-auto !grow-0;
}
.custom-form
  .form_item_row
  .custom-radio
  .ant-radio-wrapper:last-child
  span:last-child {
  @apply !pr-0;
}
.custom-form .ant-input-number-group-wrapper .ant-input-number {
  @apply !border-r-transparent !rounded-r-none;
}
.custom-form .ant-input-number {
  @apply !rounded;
}
.custom-form .ant-input-number-group-wrapper .ant-input-number-group-addon {
  @apply !border-r-others-gray !border-y-others-gray !text-body bg-white;
}
/* ===== END: custom form end ===== */

/* ====== START: modal ======> */

.custom-modal .ant-modal-header {
  @apply !px-6 !pt-6 !pb-0 !mb-0;
}
.custom-modal .ant-modal-content {
  @apply !p-0 !rounded shadow-md;
}
.custom-modal .ant-modal-header .ant-modal-title {
  @apply text-others-table-header text-[24px] font-medium leading-[36px];
}
.custom-modal .ant-modal-close {
  @apply !top-6 !text-body;
}
.custom-modal .ant-modal-body {
  @apply !max-h-[550px] !overflow-y-auto;
}
.custom-modal.custom-modal-view .ant-modal-close {
  @apply !top-1 !right-1;
}
/* <====== END: modal ====== */

/* <====== START: Button =====> */

.gray_btn .ant-btn-default {
  @apply !bg-[#D7DFE9] disabled:!bg-others-accent/50 disabled:!border-transparent hover:!bg-[#D7DFE9] !rounded-lg !text-body !text-base !border-[#D7DFE9] !font-medium leading-7;
}
.dark_btn.ant-btn-primary {
  @apply !bg-others-header hover:!bg-others-header/95;
}
.ant-btn-primary {
  @apply bg-primary !rounded disabled:!bg-primary/70 hover:!bg-primary/90 !text-white font-medium !shadow disabled:!shadow-none hover:!shadow-sm !leading-[28px] !text-[16px];
}
.action-icons .ant-btn-icon {
  @apply !mt-[3.5px];
}
.user_submit_button .ant-select-selector,
.user_submit_button .ant-select-selection-search-input {
  @apply !h-10;
}
.user_submit_button .ant-select-arrow {
  @apply !pt-2.5;
}
.user_submit_button .ant-select-clear {
  @apply !top-5;
}
.user_submit_button .ant-select-selector .ant-select-selection-placeholder,
.user_submit_button .ant-select-selection-item {
  @apply !pt-0;
}
.fc-button-group button,
.fc-today-button {
  @apply !capitalize;
}

/* <===== END: Button ======> */
/* ====== START: table design ======> */
.ant-table .ant-table-content {
  @apply overflow-x-auto;
}
.ant-table-thead tr th:first-child,
.ant-table-thead tr th:last-child {
  @apply !rounded-none;
}
.min-big-height .ant-table .ant-table-content {
  @apply !min-h-[calc(100vh-290px)];
}
.min-small-height .ant-table .ant-table-content {
  @apply !min-h-[calc(100vh-242px)];
}
.custom-table
  .ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-row-selected
  > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  @apply !bg-white;
}
.custom-table .ant-table-thead > tr th {
  @apply !bg-white !py-2 !text-header !text-base !font-medium !leading-normal first:!rounded-tl-lg last:!rounded-tr-lg;
}
.custom-table .ant-table .ant-table-cell {
  @apply whitespace-nowrap;
}

/* .custom-table .ant-table .ant-table-cell .message_Creation_Message_cell {
  @apply !whitespace-normal;
} */

.custom-table .ant-empty-normal {
  @apply !my-20;
}
.custom-table .ant-table-thead .ant-table-cell {
  @apply !px-2.5 !text-others-table-header !font-medium;
}
.custom-table .ant-table-tbody .ant-table-row .ant-table-cell {
  @apply !px-2.5 !py-3.5;
}
.custom-table .ant-table-tbody .ant-table-row .ant-table-cell,
.table_column_title h5 {
  @apply !text-header !text-sm !font-medium !leading-normal;
}
.table_column_title p {
  @apply !text-xs !leading-tight !tracking-tight !text-body !font-normal;
}
.custom-table.border_rounded .ant-table-content {
  @apply border-others-cancel border-solid;
}
.custom-table .ant-table-tbody tr .ant-table-cell {
  @apply !border-b-0;
}
.custom-table .ant-table,
.custom-table table {
  @apply !rounded;
}
.custom-table .ant-table-thead .ant-table-cell {
  @apply border-b-0 rounded-t before:!bg-transparent !text-body !text-[14px] !font-normal !leading-normal;
}
.custom-table .ant-table-content {
  @apply rounded-lg border border-solid border-transparent;
}
.custom-table .ant-table-tbody .ant-table-row .ant-table-cell {
  @apply !py-1.5 !font-normal border-others-accent border-opacity-30 border-t border-x-0 border-b-0 border-solid !text-others-body !text-sm !leading-normal;
}
.ant-table.ant-table-bordered tr > th,
.ant-table.ant-table-bordered tr > td {
  @apply !border-white;
}
/* .color-table tr:nth-child(even) td,
.color-table_reverse tr:nth-child(odd) td {
  @apply !bg-white;
} */
/* .color-table tr:nth-child(odd) td,
.color-table_reverse tr:nth-child(even) td {
  @apply !bg-[#f9fafb];
} */
.custom-table .ant-table-thead .ant-table-cell {
  @apply !py-2 !font-normal;
}
.custom-table.header_hide_normal .ant-table-thead .ant-table-cell {
  @apply !py-1.5;
}
.custom-table .ant-checkbox-inner,
.custom-table .ant-checkbox-input {
  transform: scale(1.1);
  @apply !rounded-sm;
}
.custom-table .ant-checkbox-checked .ant-checkbox-inner {
  @apply !bg-others-body !border-others-body;
}
.custom-table .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  @apply !border-others-body;
}
.custom-table .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  @apply !bg-others-body;
}
.custom-table .ant-checkbox-wrapper:hover .ant-checkbox:after {
  @apply !invisible;
}
/* ====== END: Table design ======> */
/* <====== START: Filter section =====> */
.filter_section .ant-input-affix-wrapper,
.filter_section .ant-select-selector,
.filter_section .ant-picker {
  @apply !border-others-border px-2.5 py-[5px] bg-white rounded;
}
.filter_section .ant-select-selector,
.filter_section .ant-picker {
  @apply !h-9;
}
.filter_section .ant-picker .ant-picker-input input {
  @apply !text-body placeholder:!text-others-accent text-sm font-normal leading-normal;
}
.filter_section .ant-picker .ant-picker-suffix span {
  @apply !text-others-gray;
}
/* .filter_section .ant-btn {
  @apply !h-9 !w-9 hover:!border-others-border;
} */
.filter_section .ant-select-selection-placeholder {
  @apply !pt-0.5 text-others-gray;
}
.filter_section .ant-select-selection-item,
.filter_section .ant-select-selection-search,
.custom-form .ant-select-selection-search input {
  @apply !pt-0.5 !text-header font-medium text-sm leading-normal;
}
.filter_section .ant-input-affix-wrapper .ant-input {
  @apply placeholder:text-others-accent placeholder:!text-sm placeholder:font-normal  placeholder:!leading-normal text-sm !text-body placeholder:relative placeholder:-top-[0.3px];
}
.filter_section .ant-input-affix-wrapper .ant-input-suffix,
.filter_section .ant-select-arrow,
.custom-form .ant-select-suffix,
.custom_rating .ant-rate-star-zero svg {
  @apply !text-others-gray;
}
.table_filter_section .ant-select {
  @apply !h-auto;
}
/* <====== END: Filter section =====> */
/* <====== START: Pagination design ======> */
.custom-pagination {
  @apply flex justify-center p-4 relative border border-solid border-[#E9EFF6] border-t border-b-0 border-l-0 border-r-0;
}
/* .custom-pagination .ant-pagination-item,
.custom-pagination .ant-pagination-prev,
.custom-pagination .ant-pagination-next {
  @apply !bg-transparent !rounded !border border-solid !border-others-border;
} */
.custom-pagination .ant-pagination-item a {
  @apply !text-[13px] !font-semibold !text-body mt-[1px];
}
.custom-pagination .ant-pagination-item-active {
  @apply !bg-others-body !border-others-body;
}
.custom-pagination .ant-pagination-item-active a {
  @apply !text-[#F9FAFB];
}
.custom-pagination .ant-pagination-prev,
.custom-pagination .ant-pagination-next,
.custom-pagination .ant-pagination-jump-prev,
.custom-pagination .ant-pagination-jump-next {
  @apply !text-[#F9FAFB];
}
.custom-pagination .ant-pagination-prev svg,
.custom-pagination .ant-pagination-next svg {
  @apply !w-2.5 relative top-[1.5px];
}
.custom-pagination .ant-pagination-prev.ant-pagination-disabled,
.custom-pagination .ant-pagination-next.ant-pagination-disabled {
  @apply !text-body/30;
}
.custom-pagination-row .ant-select-arrow,
.custom-form .ant-select-suffix,
.custom_rating .ant-rate-star-zero svg {
  @apply !text-header;
}
/* <====== END: Pagination design ======> */
/* ====== START: date range ======> */
.one-month-date-range
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-next-btn {
  visibility: visible !important;
}
.one-month-date-range
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}
.one-month-date-range .ant-picker-panels > *:last-child {
  display: none !important;
}
.one-month-date-range .ant-picker-panel-container,
.one-month-date-range .ant-picker-footer {
  width: 280px !important;
}
.one-month-date-range .ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
/* <====== END: date range ====== */
/* <===== START: Notification > */
.notification .ant-comment-content-author {
  @apply mb-0;
}
.ant-notification .ant-notification-notice {
  @apply !p-3 !text-others-table-header;
}
.ant-notification .ant-notification-notice .ant-notification-notice-message {
  @apply !ml-8 !text-others-table-header !font-medium;
}
.ant-notification
  .ant-notification-notice
  .ant-notification-notice-description {
  @apply !ml-8 !text-body;
}
/* <===== END: Notification > */
/* <===== START: Custom Rating ======> */
.custom_rating .ant-rate-star {
  margin-inline-end: 4px !important ;
}
.custom_rating .ant-rate {
  @apply !text-sm text-others-rating;
}
/* <===== END: Custom Rating ======> */
/* <===== START: Custom range ======> */
.custom_range {
  @apply !m-0;
}
.custom_range .ant-slider-rail {
  @apply !bg-[#FFEBD8] !h-1.5 rounded-lg;
}
.custom_range .ant-slider-track {
  @apply !bg-others-rating !h-1.5 rounded-lg;
}
.custom_range.ant-slider .ant-slider-handle::after,
.custom_range.ant-slider .ant-slider-handle:hover::after {
  box-shadow: 0 0 0 3px #fc8415 !important;
}
/* <======END: Custome range ======> */
/* <===== START: custom redio button =======>*/
.custom-radio .ant-radio-wrapper .ant-radio-inner {
  transform: scale(1.12);
}
.custom-radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  @apply transition-none left-[12.8px] top-[14.9px] w-[1.9px] h-[9px];
}
.custom-radio .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::before {
  transform: rotate(-45deg) scale(1) translate(-50%, -50%) !important;
  @apply content-[""] absolute bg-white transition-none left-[5.5px] top-[6.9px] w-[1.9px] h-[4px];
}
/* <===== END: custom redio button =======>*/
/* <====== START: upload ======>*/
.custom-upload .ant-upload {
  @apply w-full;
}
.import_excel .ant-upload {
  @apply !border-none !p-0;
}
/* <====== END: upload ======>*/
/* ===== START:view pdf start ===== */
.custom_pdf {
  @apply w-full h-[80vh] min-h-[300px];
}
#custom_pdf_viewer {
  @apply w-full;
}
#custom_pdf_viewer canvas {
  @apply w-full h-full;
}
/* <===== END:view pdf end =====> */

/* <======== START: appex chart =======> */

.apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
  /* display: none !important; */
  @apply !mb-5;
  /* upper symbolic dot */
}

.apexcharts-toolbar {
  display: none !important;
}

.custom_price_switch {
  @apply bg-others-secondary-border !h-9 !w-[86px];
}

.custom_price_switch .ant-switch-handle {
  inset-inline-start: 4px !important;
  @apply !w-[30px] !h-[30px] !rounded-full !shadow !top-[3px] !bg-others-secondary-border;
}

.custom_price_switch:hover:not(.ant-switch-disabled),
.custom_price_switch.ant-switch-checked:hover:not(.ant-switch-disabled),
.custom_price_switch.ant-switch-checked {
  @apply !bg-others-secondary-border;
}

.custom_price_switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 33px) !important;
}

.custom_price_switch .ant-switch-handle::before {
  @apply !rounded-full !shadow;
}

.custom_price_switch .ant-switch-inner .ant-switch-inner-checked,
.custom_price_switch .ant-switch-inner .ant-switch-inner-unchecked {
  @apply !text-body !text-base !font-medium !font-inter transition-none;
}

.custom_price_switch.ant-switch-checked
  .ant-switch-inner
  .ant-switch-inner-checked {
  @apply !-ml-2.5 !pt-1.5;
}

.custom_price_switch .ant-switch-inner .ant-switch-inner-unchecked {
  @apply !pl-[13px] !pt-1;
}

.partnership_count .ant-select-single .ant-select-arrow .ant-select-suffix {
  @apply after:hidden;
}

.view_wear_house .ant-modal-title {
  @apply !text-sm !font-medium;
}

.form_button_area .draft_btn,
.form_button_area .cancel-btn,
.form_button_area .ant-btn-primary {
  @apply !min-w-[8rem];
}

.ledger_report_table .ant-table-thead .ant-table-cell {
  @apply !bg-others-border !rounded-none first:!rounded-tl-none last:!rounded-tr-none !text-header;
}

.ledger_report_table .ant-table-content,
.ledger_access_log_table .ant-table-content,
.ledger_access_log_table .ant-table-container {
  @apply !rounded-none;
}

.ledger_access_log_table .ant-table-content {
  @apply !border-l-0;
}

.ledger_report_table .ant-table-tbody .ant-table-cell {
  @apply !border-r !border-b !border-l-0 !border-t-0 !border-solid last:!border-r-0 !bg-transparent;
}

.ledger_access_log_table .ant-table-thead .ant-table-cell {
  @apply !bg-others-cancel !rounded-none first:!rounded-tl-none last:!rounded-tr-none !text-body;
}
.ledger_access_log_table .ant-table-tbody .ant-table-cell {
  @apply !text-sm;
}

.filter_section .ant-picker.ledger_date_picker {
  @apply !border-[#ffa09b];
}

.filter_section .ledger_date_picker .ant-picker-input input {
  @apply relative top-[1px];
}

.filter_section .ledger_date_picker .ant-picker-input input,
.filter_section .ledger_date_picker .anticon-swap-right {
  @apply !text-primary placeholder:!text-primary;
}

.custom-form .ant-form-item.hide_margin {
  margin-bottom: 0 !important;
}

.form_custom_checkbox .ant-checkbox-inner,
.form_custom_checkbox .ant-checkbox-input {
  transform: scale(1.1);
  @apply !rounded-sm;
}

.form_custom_checkbox .ant-checkbox-checked .ant-checkbox-inner,
.custom-form
  .form_custom_checkbox
  .ant-checkbox-group
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  @apply !bg-primary !border-primary;
}

.form_custom_checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  @apply !border-primary;
}

.form_custom_checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  @apply !bg-primary;
}

.form_custom_checkbox .ant-checkbox-wrapper:hover .ant-checkbox:after {
  @apply !invisible;
}

.form_custom_checkbox .ant-checkbox-wrapper > span:last-child {
  @apply text-body text-sm font-medium leading-7 mr-3.5;
}

.checkbox_readonly .ant-checkbox-group-item,
.checkbox_readonly .ant-checkbox-group-item .ant-checkbox-input {
  @apply cursor-default;
}

.checkbox_readonly
  .ant-checkbox-wrapper.ant-checkbox-group-item:not(
    .ant-checkbox-wrapper-checked
  )
  .ant-checkbox-inner {
  @apply !border-others-border;
}

.checkbox_readonly
  .ant-checkbox-wrapper.ant-checkbox-group-item
  .ant-checkbox-inner {
  @apply !border-others-border;
}
.accent-text.ant-btn-text {
  @apply !text-others-accent disabled:!text-others-accent/40;
}
.top_bar_input .ant-input-affix-wrapper:hover,
.top_bar_input .ant-input-affix-wrapper-focused {
  @apply !border-others-body;
}
.top_bar_input .ant-input-affix-wrapper-focused {
  @apply !shadow-others-body;
}
.select_gray.ant-select .ant-select-selector {
  @apply !border-[#afbaca];
}
.select_gray.ant-select .ant-select-selector {
  @apply hover:!border-others-body;
}
.select_gray.ant-select .ant-select-selector,
.select_gray.ant-select .ant-select-arrow span {
  @apply !text-body;
}
.bill_generate_table .ant-table-thead .ant-table-cell {
  @apply !py-1;
}

/* <======= START: Tab ========> */
.custom_tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply !text-[#212529];
}

.custom_tab .ant-tabs .ant-tabs-tab-btn {
  @apply !text-[#6c757d] !text-base !font-semibold  !leading-normal;
}

.custom_tab .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  @apply !border-b-0;
  position: static !important;
  left: auto !important;
  right: auto !important;
}

.ant-tabs-top > .ant-tabs-nav {
  @apply !mb-6;
}
/* <======= END: Tab ========> */

/* <====== START: Timeline =======> */
.custom_timeline .ant-timeline .ant-timeline-item-tail {
  border-inline-start: 2px dashed #dee2e6 !important;
}
/* <====== END: Timeline =======> */

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  @apply !border-2 !border-solid !border-[#AFBACA] !rounded-lg !px-7 !py-2;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08) !important;
}

/* modal background  */
.ant-modal-root .ant-modal-mask {
  @apply !bg-opacity-0 !backdrop-blur-[3px] !bg-black/30;
}

/* sidebar toggle menu */
.sidebar_toggle_icon .ant-btn.ant-btn-lg {
  @apply !rounded-full shadow-lg;
}
.sidebar_toggle_icon .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  @apply !bg-white;
}

/* view profile */
.view_profile_btn {
  @apply !border !border-[#D7DFE9] !border-solid !rounded-md;
}

/* filtering search placeholder */
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  @apply !text-others-body;
}

/* search box placeholder */
